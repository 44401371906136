<script setup>

import { useRouter } from 'vue-router';
import { onBeforeMount } from 'vue';
import useProducts from "@/hooks/useProducts";

const route = useRouter();

const {
    fetchProduct,
    product
} = useProducts();

import ProductForm from "@/components/products/ProductForm.vue";

onBeforeMount(async () => {
    let productId = route.currentRoute.value.params.id;
    await fetchProduct(productId);
})

</script>

<template>
    <ProductForm v-if="product" :product="product"/>
</template>